import { atom, selector } from 'recoil';
import {
  Breadcrumb,
  CallToAction,
  Link,
  WarrantyItem,
  Variant,
  ColourOption,
  InteriorOption,
} from '~/common/models';

export interface VariantPageData {
  heading: string;
  introText: string;
  colourDisclaimer: string;
  warrantyTitle: string;
  selectedColour?: string;
  offerLists: Link[];
  ctas: CallToAction[];
  warranties: WarrantyItem[];
  breadcrumbs: Breadcrumb[];
  vehicleModel?: VehicleModel;
  vehicleVariant?: Variant;
  hidePriceDetails: boolean;
}

type VehicleModel = {
  modelName: string;
  modelBadgeUrl: string;
};

type ActiveCarouselImageAsset = {
  assetType: 'Interior' | 'Exterior' | undefined;
  assetId: string;
};

export const VariantPageState = atom<VariantPageData>({
  key: 'VariantPageState',
  default: {
    heading: '',
    introText: '',
    colourDisclaimer: '',
    warrantyTitle: '',
    offerLists: [],
    ctas: [],
    warranties: [],
    breadcrumbs: [],
    hidePriceDetails: false,
  },
});

export const SelectedColourState = atom<ColourOption>({
  key: 'SelectedColourState',
  default: {
    colourOptionId: '',
    name: '',
    colourCode: '',
    hexColourCode: '',
    frontImageUrl: '',
    rearImageUrl: '',
    sideImageUrl: '',
  },
});

export const SelectedInteriorState = atom<InteriorOption>({
  key: 'SelectedInteriorState',
  default: {
    interiorOptionId: '',
    imageUrl: '',
    description: '',
    trimCode: '',
    priceEffect: '',
    imageAssets: [],
    displayOrder: 0,
  },
});

export const ActiveCarouselImageAssetState = atom<ActiveCarouselImageAsset>({
  key: 'ActiveCarouselImageAssetState',
  default: {
    assetType: undefined,
    assetId: '',
  },
});

export const VariantPageVehicleModelSelector = selector<
  VehicleModel | undefined
>({
  key: 'VariantPageVehicleModelSelector',
  get: ({ get }) => get(VariantPageState).vehicleModel,
});

export const VariantPageVariantSelector = selector<Variant | undefined>({
  key: 'VariantPageVariantSelector',
  get: ({ get }) => get(VariantPageState).vehicleVariant,
});

export const VariantPageColourOptionsSelector = selector<ColourOption[]>({
  key: 'VariantPageColourOptionsSelector',
  get: ({ get }) => get(VariantPageState).vehicleVariant?.colourOptions || [],
});

export const VariantPageInteriorOptionsSelector = selector<InteriorOption[]>({
  key: 'VariantPageInteriorOptionsSelector',
  get: ({ get }) => get(VariantPageState).vehicleVariant?.interiorOptions || [],
});
