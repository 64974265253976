import React from 'react';
import cn from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColourSwatch } from '~/common/components';
import { ColourOption } from '~/common/models';
import {
  VariantPageState,
  SelectedColourState,
  VariantPageColourOptionsSelector,
} from '../../store';
import styles from './index.module.scss';

interface ColourOptionsProps {
  className?: string;
}

export const ColourOptions = React.memo<ColourOptionsProps>(({ className }) => {
  const [state] = useRecoilState(VariantPageState);
  const [, setSelectedColour] = useRecoilState(SelectedColourState);
  const colourOptions = useRecoilValue(VariantPageColourOptionsSelector);
  const [activeColour, setActiveColour] = React.useState<
    ColourOption | undefined
  >(undefined);

  React.useEffect(() => {
    let colour = colourOptions.find(
      (x) => x.colourCode === state.selectedColour
    );
    if (typeof colour === 'undefined' && colourOptions.length > 0) {
      colour = colourOptions[0];
    }

    if (!!colour) {
      setActiveColour(colour);
      setSelectedColour(colour);
    }
  }, []);

  const handleSelectColor = (colour?: ColourOption) => {
    if (!!colour) {
      setActiveColour(colour);

      setSelectedColour({ ...colour, colourOptionId: '0' });
      setTimeout(() => {
        setSelectedColour(colour);
      }, 100);
    }
  };

  return (
    <div className={cn(className, styles.colourOptionContainer)}>
      <div className={styles.displayText}>
        <p className={styles.title}>Colour</p>
        {activeColour && (
          <p className={styles.colourName}>{activeColour.name}</p>
        )}
      </div>

      <div className={styles.colourList}>
        {colourOptions.map((colour, index) => (
          <ColourSwatch
            key={index}
            colour={colour}
            className={styles.colourItem}
            onClick={() => handleSelectColor(colour)}
            isActive={activeColour?.colourOptionId === colour.colourOptionId}
            delay={(index + 1) * 80}
          />
        ))}
      </div>

      <div
        className={styles.colourDisclaimer}
        dangerouslySetInnerHTML={{ __html: state.colourDisclaimer }}
      />
    </div>
  );
});
