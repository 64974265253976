import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Carousel } from 'react-responsive-carousel';
import { VerticalCarousel, VerticalCarouselRef } from '~/common/components';
import {
  SelectedColourState,
  SelectedInteriorState,
  ActiveCarouselImageAssetState,
} from '../../store';
import styles from './index.module.scss';

type VariantImageAsset = {
  imageUrl: string;
  assetType: 'Interior' | 'Exterior';
  assetId: string;
};

interface VariantCarouselProps {
  isMobile?: boolean;
}

export const VariantCarousel = React.memo<VariantCarouselProps>(
  ({ isMobile }) => {
    const [activeColour] = useRecoilState(SelectedColourState);
    const [activeInterior] = useRecoilState(SelectedInteriorState);
    const [_, setActiveImageAsset] = useRecoilState(
      ActiveCarouselImageAssetState
    );
    const [carouselImages, setCarouselImages] = React.useState<
      VariantImageAsset[]
    >([]);
    const [activeImageIndex, setActiveImageIndex] = React.useState<number>(0);

    const verticalCarousel = React.useRef<VerticalCarouselRef>();

    useEffect(() => {
      const images: VariantImageAsset[] = [];

      if (imageNotEmpty(activeColour?.frontImageUrl)) {
        images.push({
          imageUrl: activeColour.frontImageUrl || '',
          assetType: 'Exterior',
          assetId: activeColour.colourOptionId,
        });
      }

      if (imageNotEmpty(activeColour?.rearImageUrl)) {
        images.push({
          imageUrl: activeColour.rearImageUrl || '',
          assetType: 'Exterior',
          assetId: activeColour.colourOptionId,
        });
      }

      if (imageNotEmpty(activeColour?.sideImageUrl)) {
        images.push({
          imageUrl: activeColour.sideImageUrl || '',
          assetType: 'Exterior',
          assetId: activeColour.colourOptionId,
        });
      }

      if (
        !!activeInterior.imageAssets &&
        activeInterior.imageAssets.length > 0
      ) {
        if (activeInterior.imageAssets.length > 0) {
          activeInterior.imageAssets.forEach((interiorImage) => {
            if (imageNotEmpty(interiorImage)) {
              images.push({
                imageUrl: interiorImage,
                assetType: 'Interior',
                assetId: activeInterior.interiorOptionId,
              });
            }
          });
        } else {
          images.push({
            imageUrl: activeInterior.imageUrl,
            assetType: 'Interior',
            assetId: activeInterior.interiorOptionId,
          });
        }
      }

      setCarouselImages(images);
    }, [activeColour, activeInterior]);

    useEffect(() => {
      setActiveImageIndex(0);
      verticalCarousel.current?.moveToPage(0);
    }, [activeColour.colourOptionId]);

    useEffect(() => {
      if (!!carouselImages) {
        const extCount = carouselImages.filter((c) => {
          return c.assetType === 'Exterior';
        });

        if (carouselImages.length > extCount.length) {
          setActiveImageIndex(extCount.length);
        }
      }
    }, [activeInterior.interiorOptionId]);

    const imageNotEmpty = (image?: string) => {
      return !!image && !image.includes('photo-not-avail');
    };

    const setActiveIndex = (index: number) => {
      if (carouselImages.length > index) {
        if (index !== activeImageIndex) {
          setActiveImageIndex(index);
          setActiveImageAsset({
            assetId: carouselImages[index].assetId,
            assetType: carouselImages[index].assetType,
          });
        }
      }
    };

    return (
      <div className={styles.variantCarousel}>
        {isMobile ? (
          <Carousel
            swipeable
            autoPlay={false}
            centerMode={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            showIndicators={false}
            selectedItem={activeImageIndex}
            className={styles.carouselMobile}
            onChange={(index) => {
              setActiveIndex(index);
            }}
          >
            {carouselImages.map((image, index) => (
              <img
                key={index}
                src={image.imageUrl}
                alt={`image-${index + 1}`}
                className={styles.carouselImageMobile}
                loading="lazy"
              />
            ))}
          </Carousel>
        ) : (
          <div className={styles.carouselDesktop}>
            <VerticalCarousel
              renderItems={3}
              itemHeight={75}
              ref={verticalCarousel}
              showArrows={carouselImages.length >= 4}
            >
              {carouselImages.map((image, index) => (
                <div key={index} className={styles.imageCarouselItem}>
                  <img
                    src={image.imageUrl}
                    alt={`image-${index + 1}`}
                    onClick={() => setActiveIndex(index)}
                    loading="lazy"
                  />
                </div>
              ))}
            </VerticalCarousel>

            <div className={styles.activeImage}>
              {carouselImages?.length > 0 && (
                <img
                  loading="lazy"
                  src={carouselImages[activeImageIndex].imageUrl}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);
