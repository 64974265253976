import React from 'react';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { PageLayout } from '~/common/components';
import { VariantPageData, VariantPageState } from './store';
import { VariantPageBreadcrumbs } from './components/breadcrumbs';
import { VariantHeadingPostcode } from './components/heading-postcode';
import { VariantCarousel } from './components/variant-carousel';
import { VariantWarranties } from './components/warranties';
import { VariantInfo } from './components/variant-info';
import styles from './index.module.scss';

const VariantPage = React.memo(() => (
  <div className={styles.variantPage}>
    <VariantPageBreadcrumbs />
    <VariantHeadingPostcode />

    <div className={styles.variantContent}>
      <VariantCarousel />
      <VariantInfo />
    </div>

    <VariantWarranties />
  </div>
));

export default (props: VariantPageData) => {
  const initialize = ({ set }: MutableSnapshot) => {
    set(VariantPageState, props);
  };

  return (
    <RecoilRoot initializeState={initialize}>
      <PageLayout>
        <VariantPage />
      </PageLayout>
    </RecoilRoot>
  );
};
