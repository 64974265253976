import React from 'react';
import { useRecoilState } from 'recoil';
import { Breadcrumbs } from '~/common/components/ui-elements';
import { VariantPageState } from '../../store';
import styles from './index.module.scss';

export const VariantPageBreadcrumbs = React.memo(() => {
  const [{ breadcrumbs }] = useRecoilState(VariantPageState);

  return (
    <div className={styles.breadcrumbsWrapper}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </div>
  );
});
