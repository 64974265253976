import React from 'react';
import { useRecoilState } from 'recoil';
import { PostcodeInput } from '~/common/components';
import { VariantPageState } from '../../store';
import styles from './index.module.scss';

export const VariantHeadingPostcode = React.memo(() => {
  const [{ heading }] = useRecoilState(VariantPageState);

  return (
    <div className={styles.headingPostcode}>
      <div
        className={styles.heading}
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <PostcodeInput />
    </div>
  );
});
