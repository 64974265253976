import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { SvgIcon } from '~/common/components/ui-elements';

interface VariantIntroProps {
  introText: string;
}

export const VariantIntroText = React.memo<VariantIntroProps>(
  ({ introText }) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [viewAll, setViewAll] = React.useState<boolean>();
    const [hasViewMore, setHasViewMore] = React.useState<boolean>();

    React.useLayoutEffect(() => {
      if (!!containerRef.current) {
        if (containerRef.current.clientHeight > 60) {
          setHasViewMore(true);
        }
      }
    }, []);

    return (
      <div className={styles.variantIntroWrapper}>
        <div
          ref={containerRef}
          className={cn(styles.variantIntro, {
            [styles.withViewMore]: hasViewMore && !viewAll,
          })}
          dangerouslySetInnerHTML={{ __html: introText }}
        />

        {hasViewMore && (
          <div
            className={styles.viewMoreButton}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? (
              <>
                <span>View less</span>
                <SvgIcon type="chevronUp" color="#1637A0" size="10px" />
              </>
            ) : (
              <>
                <span>View more</span>
                <SvgIcon type="chevronDown" color="#1637A0" size="10px" />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);
