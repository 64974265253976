import React from 'react';
import cn from 'classnames';
import { useRecoilState } from 'recoil';
import { VariantPageState } from '../../store';
import styles from './index.module.scss';

export const VariantWarranties = React.memo(() => {
  const [state] = useRecoilState(VariantPageState);

  return (
    <div className={styles.warranties}>
      <div className={styles.warrantiesWrapper}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: state.warrantyTitle }}
        />
        <div className={styles.warrantyList}>
          {state.warranties?.map((warranty, index) => (
            <div
              key={index}
              className={cn(styles.warrantyItemWrapper, {
                [styles.hideOnMobile]: warranty.warrantyHideOnMobile,
              })}
            >
              {index > 0 && (
                <div
                  className={cn(styles.warrantyDivider, {
                    [styles.hideOnMobile]: warranty.warrantyHideOnMobile,
                  })}
                />
              )}

              <div className={styles.warrantyItem}>
                <img
                  src={warranty.warrantyImageIcon}
                  alt={warranty.warrantyDesktopTitle}
                />
                <div
                  className={styles.desktopTitle}
                  dangerouslySetInnerHTML={{
                    __html: warranty.warrantyDesktopTitle,
                  }}
                />
                <div
                  className={styles.mobileTitle}
                  dangerouslySetInnerHTML={{
                    __html: warranty.warrantyMobileTitle,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
